// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "RumUserMonitoring": {
        "RumMonitorId": "8159c1fe-5122-4e70-95d3-122ae0443fe0",
        "RumRegion": "eu-central-1",
        "RumTelemetries": "errors,http"
    },
    "aws_ssm_param_rmi_company_name": "/special-tools/develop/rmi-service-company",
    "aws_ssm_param_rmi_secret_name": "/special-tools/develop/rmi-service-password",
    "aws_ssm_param_rmi_user_name": "/special-tools/develop/rmi-service-username",
    "aws_ssm_param_rmi_type_id": "/special-tools/develop/rmi-service-type-id",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:d4b08eda-c3f5-4fca-a590-f4b948d2af21",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://develop.d1nrrspi5bfsk1.amplifyapp.com/,https://develop.rmi.dev.imagesimilarity.tecalliance.net/,https://rmi.dev.imagesimilarity.tecalliance.net/",
        "domain": "tecrmi-auth-develop.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://develop.d1nrrspi5bfsk1.amplifyapp.com/,https://develop.rmi.dev.imagesimilarity.tecalliance.net/,https://rmi.dev.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_9iTLf1qOj",
    "aws_user_pools_web_client_id": "43d62tt7agkhm0lvg4389tpmm9",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "TecRMIdevelopFrooAzureADB48E20C9",
    "aws_cognito_social_providers": [],
    "aws_appsync_apiKey": "da2-rkxho3h3onhdla6ug4wdtelv2i",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://jo6bmxbke5gbpgxzztlhoulapu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "tecrmi-develop-frontend",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "backend": {
        "region": "eu-north-1",
        "bucket_name": "tecrmi-manuals-develop"
    },
    "amplify_appid": "d1nrrspi5bfsk1",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
